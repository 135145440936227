// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rules_container__cK9Sf {
  margin-top: 350px;
}
.Rules_container__cK9Sf .Rules_title__HqeXm {
  font-weight: 500;
  font-size: 40px;
  line-height: 91%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-align: center;
  font-family: "DrukWideCyr_medium";
  color: #ffffff;
  text-align: center;
}
.Rules_container__cK9Sf .Rules_rules__GstV7 {
  display: flex;
  gap: 24px;
  margin-top: 20px;
}
.Rules_container__cK9Sf .Rules_rules__GstV7 .Rules_nextArrow__UXlXE {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.Rules_container__cK9Sf .Rules_rules__GstV7 .Rules_prevArrow__oTt3Z {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 1200px) {
  .Rules_container__cK9Sf .Rules_rules__GstV7 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 850px) {
  .Rules_container__cK9Sf {
    margin-top: 35px;
  }
  .Rules_container__cK9Sf .Rules_rules__GstV7 {
    display: block;
  }
}
@media (max-width: 500px) {
  .Rules_container__cK9Sf .Rules_title__HqeXm {
    font-size: 20px;
  }
  .Rules_container__cK9Sf .Rules_rules__GstV7 {
    width: unset;
    z-index: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Rules/Rules.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAEE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iCAAA;EACA,cAAA;EACA,kBAAA;AAAJ;AAOE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AALJ;AAOI;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;AALN;AAQI;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;EACA,UAAA;AANN;;AAWA;EAEI;IACE,qCAAA;EATJ;AACF;AAaA;EACE;IACE,gBAAA;EAXF;EAaE;IAIE,cAAA;EAdJ;AACF;AAkBA;EAEI;IACE,eAAA;EAjBJ;EAmBE;IACE,YAAA;IACA,UAAA;EAjBJ;AACF","sourcesContent":["@import \"../../assets/styles/global.scss\";\n\n.container {\n  margin-top: 350px;\n  .title {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 91%;\n    letter-spacing: -0.01em;\n    text-transform: uppercase;\n    text-align: center;\n    font-family: \"DrukWideCyr_medium\";\n    color: #ffffff;\n    text-align: center;\n  }\n\n  .sliderWrapper {\n    // position: absolute;\n  }\n\n  .rules {\n    display: flex;\n    gap: 24px;\n    margin-top: 20px;\n\n    .nextArrow {\n      position: absolute;\n      right: 0;\n      top: 50%;\n      transform: translateY(-50%);\n      z-index: 1;\n    }\n\n    .prevArrow {\n      position: absolute;\n      left: 0;\n      top: 50%;\n      transform: translateY(-50%);\n      z-index: 1;\n    }\n  }\n}\n\n@media (max-width: 1200px) {\n  .container {\n    .rules {\n      grid-template-columns: repeat(2, 1fr);\n    }\n  }\n}\n\n@media (max-width: 850px) {\n  .container {\n    margin-top: 35px;\n\n    .rules {\n      // grid-template-columns: repeat(1, 1fr);\n      // margin: 0 auto;\n      // width: 60%;\n      display: block;\n    }\n  }\n}\n\n@media (max-width: 500px) {\n  .container {\n    .title {\n      font-size: 20px;\n    }\n    .rules {\n      width: unset;\n      z-index: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Rules_container__cK9Sf`,
	"title": `Rules_title__HqeXm`,
	"rules": `Rules_rules__GstV7`,
	"nextArrow": `Rules_nextArrow__UXlXE`,
	"prevArrow": `Rules_prevArrow__oTt3Z`
};
export default ___CSS_LOADER_EXPORT___;
