import styles from './SteamLogin.module.scss'
import person from '../../assets/images/icons/person.svg'
import warning from '../../assets/images/icons/warning.svg'
import steam from '../../assets/images/icons/steam-btn.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SteamLogin = () => {
  const { t } = useTranslation(['main', 'profile'])

  return (
    <div className={styles.steam}>
      <div className={styles.title}>
        <img src={person} alt="" />
        Steam Login
      </div>
      <div className={styles.txt}>
        <img src={warning} alt="" />
        {t('profile:steam_is_not_attached')}
      </div>
      <div className={styles.btn}>
        <NavLink to={`${process.env.REACT_APP_API_URL}/auth/steam`}>
          <button>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9794 0C5.6701 0 0.494845 4.85714 0 11.0408L6.43299 13.6939C6.96907 13.3265 7.62887 13.102 8.35052 13.102C8.41237 13.102 8.47423 13.102 8.53608 13.102L11.4021 8.95919V8.89796C11.4021 6.40817 13.4433 4.36735 15.9381 4.36735C18.433 4.36735 20.4742 6.38776 20.4742 8.89796C20.4742 11.3878 18.433 13.4286 15.9381 13.4286C15.8969 13.4286 15.8763 13.4286 15.8351 13.4286L11.7526 16.3469C11.7526 16.4082 11.7526 16.449 11.7526 16.5102C11.7526 18.3878 10.2268 19.898 8.35052 19.898C6.70103 19.898 5.31959 18.7143 5.01031 17.1633L0.412371 15.2653C1.83505 20.3061 6.47423 24 11.9794 24C18.6186 24 24 18.6327 24 12C23.9794 5.36735 18.5979 0 11.9794 0ZM7.52577 18.2041L6.04124 17.5918C6.30928 18.1429 6.76289 18.5918 7.36082 18.8367C8.65979 19.3673 10.1649 18.7551 10.701 17.4694C10.9691 16.8367 10.9691 16.1429 10.701 15.5102C10.433 14.8776 9.95876 14.3878 9.31959 14.1224C8.70103 13.8571 8.02062 13.8776 7.4433 14.102L8.96907 14.7347C9.91753 15.1429 10.3711 16.2245 9.97938 17.1837C9.58763 18.1633 8.49485 18.6122 7.52577 18.2041ZM18.9485 8.89796C18.9485 7.2449 17.5876 5.87755 15.9381 5.87755C14.268 5.87755 12.9278 7.22449 12.9278 8.89796C12.9278 10.551 14.2887 11.9184 15.9381 11.9184C17.6082 11.9184 18.9485 10.5714 18.9485 8.89796ZM13.6701 8.89796C13.6701 7.65306 14.6804 6.63265 15.9381 6.63265C17.1959 6.63265 18.2062 7.65306 18.2062 8.89796C18.2062 10.1429 17.1959 11.1633 15.9381 11.1633C14.6804 11.1633 13.6701 10.1429 13.6701 8.89796Z" fill="#ffffff" />
            </svg>
            Steam login
          </button>
        </NavLink>
      </div>
    </div>
  )
}

export default SteamLogin