import Button from "../../../components/Button/Button";
import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import styles from "../QuestGuide/QuestGuide.module.scss";
import arrow from "../../../assets/images/icons/arrow-right.svg";
import arrowYellow from "../../../assets/images/icons/arrow-yellow.svg";
import second from "../../../assets/images/welcome/second.png";
import { useTranslation } from "react-i18next";
import back_arrow from "../../../assets/images/back_arrow.png";
import pic_one from "../../../assets/images/quest_guide_one.png";
import pic_two from "../../../assets/images/quest_guide_two.png";
import { NavLink } from "react-router-dom";

const QuestGuide = ({ onClickPrevious, onClickNext }) => {
  const { t } = useTranslation(["main", "welcome"]);

  return (
    <div className={`${styles.container} container-main`}>
      <WelcomeHeader />
      <div className={styles.wrapper}>
        <div onClick={onClickPrevious} className={styles.back}>
          <img src={back_arrow} alt="" />
        </div>
        <div className={styles.title}>
        НАЛАШТУЙСЯ
        НА ПЕРЕМОГУ
        </div>
        <div className={styles.info}>
          <div className={styles.group_one}>
            <img src={pic_one} alt="Quest Guide One" />
            <div className={styles.obj_one}>
              <h1 className={styles.title}>Перша нагорода</h1>
              <p className={styles.desc}>Отримай 300 XP 
              за прив’язку акаунта Steam</p>
            </div>
          </div>
          <div className={styles.group_two}>
            <img src={pic_two} alt="Quest Guide Two" />
            <div className={styles.obj_two}>
              <h1 className={styles.title}>Трейд посилання</h1>
              <p className={styles.desc}>Додай своє посиланням для обміну зі Steam,
              щоб отримувати ігрові скіни і предмети у магазині.</p>
            </div>
          </div>
        </div>
        <div onClick={onClickNext} className={styles.btn}>

       <NavLink to="/" className={styles.skipBtn}>
      <button>
      Залутати
        {/* <img src={arrow} alt="" /> */}
      </button>
    </NavLink>
          {/* <button>
            {t("next")}
            <img src={arrow} alt="" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default QuestGuide;
