// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Welcome_container__NV\\+Tc {
  height: 100vh;
  background-color: #181511;
  background-repeat: no-repeat;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/Welcome/Welcome.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,yBAAA;EACA,4BAAA;EACA,sBAAA;AADF","sourcesContent":["@import \"../../assets/styles/global.scss\";\n\n.container {\n  height: 100vh;\n  background-color: #181511;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Welcome_container__NV+Tc`
};
export default ___CSS_LOADER_EXPORT___;
