import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Faq.module.scss";
import arrowup from "../../assets/images/icons/arrow-up.svg";
import arrowdown from "../../assets/images/icons/arrowdown.svg";
import list from "../../assets/images/icons/list.svg";
import { useTranslation } from "react-i18next";
import telegram from "../../assets/images/social/telegram.svg";
import phone from "../../assets/images/social/phone.svg";

const Faq = () => {
  const [openItems, setOpenItems] = useState({});

  const { t } = useTranslation(["faq", "main"]);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          const id = element.key;
          console.log(hash.slice(-1));
          setOpenItems((prevState) => ({
            ...prevState,
            [hash.slice(-1)]: true,
          }));
        }
      }, 5);
    }
  }, [hash]);

  const faqData = [
    {
      id: 1,
      question: t("question1"),
      answer: `<div class="${styles.paragraph}">${t("answer1_text1")}</div>
      <div class="${styles.paragraph}">${t("answer1_text2")}</div>
      
      `,
      anchor: "question1",
    },
    {
      id: 2,
      question: t("question2"),
      answer: t("answer2"),
      anchor: "question2",
    },
    {
      id: 3,
      question: t("Як відкрити інвентар у Steam для отримання предметів?"),
      answer: `<div class="${styles.paragraph}">${t("answer6_text1")}</div>
      <div class="${styles.paragraph}">${t("answer6_text2")}</div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t("answer6_text3")}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t("answer6_text4")}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t("answer6_text5")}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" />${t("answer6_text6")}
      </div>
      <div class="${styles.listItem}">
        <img src="${list}" class="${styles.listImg}" />${t("answer6_text7")}
      </div>
      `,
      anchor: "question3",
    },
    {
      id: 4,
      question: t("question3"),
      answer: `<div class="${styles.paragraph}">${t("answer3_text1")}</div>
      
      `,
      anchor: "question4",
    },
    {
      id: 5,
      question: t("question4"),
      answer: `<div class="${styles.paragraph}">${t("answer4_text1")}</div>
     `,
      anchor: "question5",
    },
    {
      id: 6,
      question: t("question5"),
      answer: t("answer5"),
      anchor: "question6",
    },
    {
      id: 7,
      question: "Чому я не можу зробити покупку в магазині?",
      answer: `<div class="${styles.paragraph}">Це може бути пов'язано з однією з наступних причин:</div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Немає трейд посилання в профілі: Переконайся, що у тебе вказано актуальне трейд посилання.

        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Закрито інвентар у Steam: відкрий інвентар у налаштуваннях приватності Steam.

        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Перевищив ліміт покупок на добу: зачекай і спробуй знову пізніше.

        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Недостатньо ХP балів на балансі: перевір, чи вистачає у тебе балів для покупки.

        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Перевір, чи не накладено на твій акаунт трейд-бан у Steam. -> <a target="_blank" class="${styles.link}" href="https://help.steampowered.com/ru/faqs/view/451E-96B3-D194-50FC">Перейти</a>
        </div>
        <div class="${styles.paragraph} ${styles.listItemBig}">Якщо всі перераховані вище пункти перевірені, але проблема зберігається, будь ласка, зв'яжись з нашою службою підтримки.
</div>
      `,
      anchor: "question7",
    },
    {
      id: 8,
      question: "Проблема з трейд посиланням",
      answer: `<div class="${styles.paragraph}">Це може бути пов'язано з однією з наступних причин:</div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Немає трейд-посилання в профілі.

        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Трейд-посилання в ХPass не збігається з трейд-посиланням у Steam
        </div>
        <div class="${styles.listItem} ${styles.listItemBig}">
          <img src="${list}" />Стався трейд-лок через видалення/додавання аутентифікатора Steam. Дізнатися чому  -> <a target="_blank" class="${styles.link}" href="https://help.steampowered.com/ru/faqs/view/7EFD-3CAE-64D3-1C31">Перейти</a>
        </div>
        <div class="${styles.paragraph} ${styles.listItemBig}">Якщо всі перераховані вище пункти перевірені, але проблема зберігається, будь ласка, зв'яжись з нашою службою підтримки.
</div>
      `,
      anchor: "question8",
    },
    {
      id: 9,
      question: t("main:help"),
      answer: `
      <div class="${styles.help}">
       
        <a class="${styles.phone}" href='mailto:support@viphouse.com' target='_blank'>
  <img src=${phone} alt="" />
  support@viphouse.com
</a>

      </div>
      `,
      anchor: "question9",
    },
  ];

  const toggleAccordion = (id) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className={`${styles.container} container-main`}>
      <div className={styles.title}>найпоширеніші запитання</div>
      {faqData.map((item, index) => (
        <div key={item.id} id={item.anchor} className={styles.item}>
          <div
            className={`${styles.question} ${
              openItems[item.id] ? `${styles.active}` : ""
            }`}
            onClick={() => toggleAccordion(item.id)}
          >
            <div>{item.question}</div>
            <div className={styles.arrow}>
              {openItems[item.id] ? (
                <img src={arrowup} alt="" />
              ) : (
                <img src={arrowdown} alt="" />
              )}
            </div>
          </div>

          <div
            className={`${styles.answer} ${
              openItems[item.id] ? `${styles.open}` : ""
            }`}
          >
            <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
