// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/Roboto/include.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,4CAAA;EACA,gBAAA;AACJ;AAEA;EACI,qBAAA;EACA,4CAAA;EACA,gBAAA;AAAJ;AAGA;EACI,qBAAA;EACA,4CAAA;EACA,gBAAA;AADJ","sourcesContent":["@font-face {\n    font-family: 'Roboto';\n    src: url('./Roboto-Regular.ttf');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('./Roboto-Medium.ttf');\n    font-weight: 500;\n}\n\n@font-face {\n    font-family: 'Roboto';\n    src: url('./Roboto-Bold.ttf');\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
