// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_container__2mhQ\\+ {
  align-items: center;
  background-color: #11362C;
  color: #07EF9C;
  display: flex;
  gap: 8px;
  padding: 10px 10px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.Toast_container__2mhQ\\+ .Toast_icon__359tO {
  height: 24px;
  width: 24px;
}
.Toast_container__2mhQ\\+ .Toast_message__SHRRp {
  width: 80%;
}

.Toast_warning__TeM54 {
  background-color: #333217;
  color: #EAD40C;
}

.Toast_error__7-0nq {
  background-color: #351C1D;
  color: #F44336;
}`, "",{"version":3,"sources":["webpack://./src/components/Toast/Toast.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AAEE;EACE,UAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,cAAA;AADF;;AAIA;EACE,yBAAA;EACA,cAAA;AADF","sourcesContent":[".container {\n  align-items: center;\n  background-color: #11362C;\n  color: #07EF9C;\n  display: flex;\n  gap: 8px;\n  padding: 10px 10px;\n  border-radius: 5px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  .icon {\n    height: 24px;\n    width: 24px;\n  }\n\n  .message {\n    width: 80%;\n  }\n}\n\n.warning {\n  background-color: #333217;\n  color: #EAD40C;\n}\n\n.error {\n  background-color: #351C1D;\n  color: #F44336;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Toast_container__2mhQ+`,
	"icon": `Toast_icon__359tO`,
	"message": `Toast_message__SHRRp`,
	"warning": `Toast_warning__TeM54`,
	"error": `Toast_error__7-0nq`
};
export default ___CSS_LOADER_EXPORT___;
