import { NavLink } from "react-router-dom";
import styles from "./Intro.module.scss";
import arrow from "../../../assets/images/icons/arrow-right.svg";
import first from "../../../assets/images/welcome/first-mob.png";
import { useTranslation } from "react-i18next";
import intro_pic from "../../../assets/images/intro_welcome.png";

const Intro = ({ onClickNext }) => {
  const { t } = useTranslation(["welcome", "main"]);

  return (
    <div className={`${styles.container} container-main`}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            <span style={{ color: "#AB886A" }}>Х-PASS</span> — ТВОЯ ПЕРЕПУСТКА У
            ВСЕСВІТ КРУТИХ ВИКЛИКІВ І ПРИЗІВ
          </div>
          <div className={styles.info}>
            <div className={styles.txt}>
              Здобувай очки досвіду (ХP) у квестах  та обмінюй їх на
              топові гаджети, ігрові скіни,  екслюзивний мерч, фрібети і
              фріспіни.
            </div>
          </div>
          <div onClick={onClickNext} className={styles.btn}>
            <button>
              Далі
              <img src={arrow} alt="" />
            </button>
          </div>
        </div>

        <img src={intro_pic} className={styles.intro_pic} alt="intro" />
      </div>
    </div>
  );
};

export default Intro;
