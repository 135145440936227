import { useState } from "react";
import styles from "./OfferRules.module.scss";
import close from "../../../assets/images/icons/close-btn.svg";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";

const OfferRules = ({ closeForm, onRegisterClick }) => {
  const { t } = useTranslation(["offer"]);
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      console.log("hello");
      closeForm();
    }
  };

  return (
    <div className={styles.wrapper} onClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={styles.closeBtn} onClick={onRegisterClick}>
          <img src={close} alt="" />
        </div>
        <div className={styles.title}>{t("offer_header")}</div>
        <div className={styles.offer}>
          <p>{t("p1")}</p>
          <p>{t("p2")}</p>
          <p>
            <strong>{t("Пропозиція")} - </strong>
            {t("акція “VIP Pass”, яка проводиться Організатором; ")}
          </p>
          <p>
            <strong>{t("Організатор")} - </strong>
            {t(
              "(далі Сайт) ресурс Vip House та відповідальні особи у його складі; "
            )}
          </p>
          <p>
            <strong>{t("Ігровий аккаунт")} – </strong>
            {t(
              "особистий обліковий запис Учасника, зареєстрований в Організатора; "
            )}
          </p>
          <p>
            <strong>{t("Ігровий ідентифікатор")} – </strong>
            {t(
              "номер Ігрового аккаунта Учасника, який зареєстрований в Організатора; ​​Основні правила – правила роботи, прийому ставок та парі Організатора, які розміщуються на Сайті. "
            )}
          </p>
          <p>
            <strong>{t("Термін проведення")} – </strong>
            {t(
              "часовий проміжок встановлений Організатором, який починається 04.09.2024 00:00 і триватиме до офіційного інформування Організатором про завершення Пропозиції; "
            )}
          </p>
          <p>
            <strong>{t("Місце проведення Пропозиції")} – </strong>
            {t(
              "Пропозиція проводиться на Сайті VIP Pass."
            )}
          </p>
          <p>
            <strong>{t("Правила")} – </strong>
            {t("офіційні правила Пропозиції, що розміщуються на Сайті. ")}
          </p>
          <p>
            <strong>{t("Учасник ")} – </strong>
            {t(
              "гравець, який має валідний Ігровий аккаунт на ресурсі Організатора;"
            )}
          </p>
          <p>
            <strong>{t("Приз ")} – </strong>
            {t("виграш отриманий за XP в результаті активності в Пропозиції; ")}
          </p>
          <p>
            <strong>{t("XP")} – </strong>
            {t(
              "одиниця прогресу Учасника на Ігровому акаунті, отриманого в результаті виконання умов Квесту-(ів).  "
            )}
          </p>

          <p>
            <strong>{t("FreeBet")} – </strong>
            {t("ставка без ризику за рахунок Організатора; ")}
          </p>
          <p>
            <strong>{t("FreeSpins")} – </strong>
            {t(
              "безкоштовні прокрути у тій чи іншій грі казино на ресурсі Організатора; "
            )}
          </p>
          <p>
            <strong>{t("SportBonus")} – </strong>
            {t(
              "грошовий єквівалент на аккаунті Учасника, який може бути використаний на Сайті для формування спортивних ставок. Цей бонус потребує відіграш та ці умови будуть зазначені в детальних правилах отриманного бонусу. "
            )}
          </p>
          <p>
            <strong>{t("Фізичний подарунок (та Гаджети)")} – </strong>
            {t(
              "матеріальний предмет, який Учасник може отримати у виді Приза в разі виконання умов Пропозиції. Фізичний подарунок є реальним товаром, який буде доставлено або вручено особисто на підставі попередніх домовленостей з Організатором. "
            )}
          </p>
          <p>
            <strong>{t(" Інвентар та Скіни")} – </strong>
            {t(
              "ігровий продукт платформи “Steam” отриманий Учасником у виді Приза. Даний Приз не може бути використаним в Ігровому акаунті в Організатора, так як є стороннім продуктом. Інформація про вартість та зміст Інвентаря або Скіна зазначена в умовах Призу."
            )}
          </p>
          <p>
            <strong>{t("Квести")} – </strong>
            {t(
              "підбірка завдань, за виконання яких Учасник отримує XP, доступні на Ігровому аккаунті; "
            )}
          </p>
          <p>
            <strong>{t("Обліковий запис Steam")} – </strong>
            {t(
              "особистий обліковий запис Учасника на ресурсі “Steam”, який буде застосований для отримання Інвентаря та Скінів (Призів).  "
            )}
          </p>

          <p>
            <strong>{t("main_terms")}</strong>
          </p>
          <p>
            <strong>1.1. </strong>
            {t("p19")}
          </p>
          <p>
            <strong>1.2. </strong>
            {t("p20")}
          </p>
          <p>
            <strong>1.3. </strong>
            {t("p21")}
          </p>
          {/* <p><strong>1.4. </strong>{t('p22')}</p>
          <p><strong>1.5. </strong>{t('p23')}</p>
          <p><strong>1.6. </strong>{t('p24')}</p>
          <p><strong>1.7. </strong>{t('p25')}</p> */}

          <p>
            <strong>{t("main_terms_2")}</strong>
          </p>
          <p>
            <strong>2.1. </strong>
            {t("p26")}
          </p>
          <p>
            <strong>2.1.1. </strong>
            {t("p27")}
          </p>
          <p>
            <strong>2.1.2. </strong>
            {t("p28")}
          </p>
          <p>
            <strong>2.1.3. </strong>
            {t("p29")}
          </p>
          {/* <p><strong>2.2. </strong>{t('p30')}</p>
          <p><strong>2.3. </strong>{t('p31')}</p>
          <p><strong>2.4. </strong>{t('p32')}</p>
          <p><strong>2.5. </strong>{t('p33')}</p> */}

          <p>
            <strong>{t("main_terms_3")}</strong>
          </p>
          <p>
            <strong>3.1. </strong>
            {t("p34")}
          </p>
          <p>
            <strong>3.2. </strong>
            {t("p35")}
          </p>
          <p>
            <strong>3.3. </strong>
            {t("p36")}
          </p>
          <p>
            <strong>3.4. </strong>
            {t("p37")}
          </p>

          <p>
            <strong>{t("main_terms_4")}</strong>
          </p>
          <p>
            <strong>4.1. </strong>
            {t("p38")}
          </p>
          <p>
            <strong>4.2. </strong>
            {t("p39")}
          </p>
          <p>
            <strong>4.3. </strong>
            {t("p40")}
          </p>
          <p>
            <strong>4.4. </strong>
            {t("p41")}
          </p>
          <p>
            <strong>4.5. </strong>
            {t("p42")}
          </p>
          <p>
            <strong>4.6. </strong>
            {t("p43")}
          </p>
          <p>
            <strong>4.7. </strong>
            {t("p44")}
          </p>
          {/* <p><strong>4.8. </strong>{t('p45')}</p>
          <p><strong>4.9. </strong>{t('p46')}</p> */}

          <p>
            <strong>{t("main_terms_5")}</strong>
          </p>
          <p>
            <strong>5.1. </strong>
            {t("p45")}
          </p>
          <p>
            <strong>5.2. </strong>
            {t("p46")}
          </p>
          <p>
            <strong>5.3. </strong>
            {t("p47")}
          </p>
          <p>
            <strong>5.4. </strong>
            {t("p48")}
          </p>
          <p>
            <strong>5.5. </strong>
            {t("p49")}
          </p>
          <p>
            <strong>5.6. </strong>
            {t("p50")}
          </p>
          <p>
            <strong>5.7. </strong>
            {t("p51")}
          </p>
        </div>
        <div className={styles.btn} onClick={onRegisterClick}>
          <Button title="Ок" />
        </div>
      </div>
    </div>
  );
};

export default OfferRules;
