// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_container__qRlyu {
  position: relative;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".container {\n  position: relative;\n  overflow-x: hidden;\n}\n\n// .auth {\n//   background-image: url('../../assets/images/background-reg.jpg');\n//   background-size: cover;\n//   background-repeat: no-repeat;\n// }\n\n// .purple {\n//   background-image: url('../../assets/images/main.jpg');\n//   background-size: cover;\n//   background-repeat: no-repeat;\n// }\n\n// @media (max-width: 850px) {\n//   .auth {\n//     background-image: url('../../assets/images/background-reg-mob.jpg');\n//   }\n\n//   .purple {\n//     background-image: none;\n//   }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Home_container__qRlyu`
};
export default ___CSS_LOADER_EXPORT___;
