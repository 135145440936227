// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./HelveticaNeueCyr-Roman.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./HelveticaNeueCyr-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./HelveticaNeueCyr-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./HelveticaNeueBlackCondensed.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "HelveticaNeue";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 400;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 500;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 700;
}
@font-face {
  font-family: "HelveticaNeueBlackCondensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: 900;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/HelveticaNeue/include.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,4CAAA;EACA,gBAAA;AACJ;AAEA;EACI,4BAAA;EACA,4CAAA;EACA,gBAAA;AAAJ;AAGA;EACI,4BAAA;EACA,4CAAA;EACA,gBAAA;AADJ;AAIA;EACI,0CAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AAFJ","sourcesContent":["@font-face {\n    font-family: 'HelveticaNeue';\n    src: url('./HelveticaNeueCyr-Roman.ttf');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: 'HelveticaNeue';\n    src: url('./HelveticaNeueCyr-Medium.ttf');\n    font-weight: 500;\n}\n\n@font-face {\n    font-family: 'HelveticaNeue';\n    src: url('./HelveticaNeueCyr-Bold.ttf');\n    font-weight: 700;\n}\n\n@font-face {\n    font-family: 'HelveticaNeueBlackCondensed';\n    src: url('./HelveticaNeueBlackCondensed.ttf') format('truetype');\n    font-weight: 900; \n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
