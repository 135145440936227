import styles from './TradeLinkHelp.module.scss'
import list from '../../assets/images/icons/list-yellow.svg'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'

const TradeLinkHelp = ({ closeForm }) => {
  const { t } = useTranslation(['main', 'help', 'welcome'])

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeForm()
    }
  }

  return (
    <div className={styles.wrapper}
      onClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={styles.title}>
        Як отримати посилання
        </div>
        {/* <div className={styles.subtitle}>
          {t('help:find_trade_link')}
        </div> */}

        <div className={styles.list}>
          <div className={styles.listItem}>
            <img className={styles.listImage}
              src={list}
              alt="" />
            Увійди у Steam
          </div>
          <div className={styles.listItem}>
            <img className={styles.listImage}
              src={list}
              alt="" />
            Наведи на свій нікнейм, щоб випало меню профілю
          </div>
          <div className={styles.listItem}>
            <img className={styles.listImage}
              src={list}
              alt="" />
            Перейди до «Інвентар»
          </div>
          <div className={styles.listItem}>
            <img className={styles.listImage}
              src={list}
              alt="" />
            Натисни кнопку «Пропозиції обміну»
          </div>
          <div className={styles.listItem}>
            <img className={styles.listImage}
              src={list}
              alt="" />
            Відкрий «Хто може надсилати мені пропозиції обміну?»
          </div>
          <div className={styles.listItem}>
          <img className={styles.listImage}
              src={list}
              alt="" />
          У графі «Сторонні сайти» знайди поле «Посилання на обмін» та скопіюй адресу
          </div>
        </div>

        <div className={styles.btn}>
          <div onClick={closeForm}>
            <Button title={t('Зрозуміло')} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TradeLinkHelp