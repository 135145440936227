// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./SFProText-Semibold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./SFProText-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SFPro_semibold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 600;
}
@font-face {
  font-family: "SFPro_regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/SFPro/include.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,4CAAA;EACA,gBAAA;AACF;AAEA;EACE,4BAAA;EACA,4CAAA;EACA,gBAAA;AAAF","sourcesContent":["@font-face {\n  font-family: 'SFPro_semibold';\n  src: url('./SFProText-Semibold.ttf');\n  font-weight: 600;\n}\n\n@font-face {\n  font-family: 'SFPro_regular';\n  src: url('./SFProText-Regular.ttf');\n  font-weight: 400;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
