// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_wrapper__hTwrt {
  position: relative;
}
.Profile_wrapper__hTwrt .Profile_links__We7jv {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-top: 24px;
}

@media (max-width: 850px) {
  .Profile_wrapper__hTwrt .Profile_links__We7jv {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,gBAAA;AACJ;;AAGA;EAGE;IACE,cAAA;EAFF;AACF","sourcesContent":[".wrapper {\n  position: relative;\n\n  .links {\n    display: flex;\n    gap: 24px;\n    justify-content: space-between;\n    margin-top: 24px;\n  }\n}\n\n@media (max-width: 850px) {\n .wrapper {\n\n  .links {\n    display: block;\n  }\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Profile_wrapper__hTwrt`,
	"links": `Profile_links__We7jv`
};
export default ___CSS_LOADER_EXPORT___;
