// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WelcomeHeader_header__Z\\+Vj7 {
  align-items: center;
  display: flex;
  font-family: "HelveticaNeue";
  font-weight: 500;
  justify-content: space-between;
  left: 0;
  margin: 0 auto;
  padding-top: 26px;
  position: absolute;
  right: 0;
  top: 0;
  width: 95%;
  z-index: 2;
}
.WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_left__U6rVL {
  align-items: center;
  display: flex;
  gap: 40px;
}
.WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_right__L4Sro {
  display: flex;
  gap: 24px;
}
.WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_right__L4Sro .WelcomeHeader_lang__wH28U {
  align-items: center;
  color: #ffffff;
  gap: 4px;
  display: flex;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-weight: 400;
}
.WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_right__L4Sro .WelcomeHeader_lang__wH28U .WelcomeHeader_langItem__rLc5I {
  cursor: pointer;
  transition: all 0.2s linear;
}
.WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_right__L4Sro .WelcomeHeader_yellow__pKUFd {
  color: #EAD40C;
}

@media (max-width: 850px) {
  .WelcomeHeader_header__Z\\+Vj7 .WelcomeHeader_left__U6rVL img {
    width: 120px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Welcome/WelcomeHeader/WelcomeHeader.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,aAAA;EACA,4BAAA;EACA,gBAAA;EACA,8BAAA;EACA,OAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,MAAA;EACA,UAAA;EACA,UAAA;AADF;AAGE;EACE,mBAAA;EACA,aAAA;EACA,SAAA;AADJ;AAIE;EACE,aAAA;EACA,SAAA;AAFJ;AAII;EACE,mBAAA;EACA,cAAA;EACA,QAAA;EACA,aAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;AAFN;AAIM;EACE,eAAA;EACA,2BAAA;AAFR;AAMI;EACE,cAAA;AAJN;;AASA;EAIM;IACE,YAAA;EATN;AACF","sourcesContent":["@import '../../../assets/styles/global.scss';\n\n.header {\n  align-items: center;\n  display: flex;\n  font-family: 'HelveticaNeue';\n  font-weight: 500;\n  justify-content: space-between;\n  left: 0;\n  margin: 0 auto;\n  padding-top: 26px;\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 95%;\n  z-index: 2;\n\n  .left {\n    align-items: center;\n    display: flex;\n    gap: 40px;\n  }\n\n  .right {\n    display: flex;\n    gap: 24px;\n\n    .lang {\n      align-items: center;\n      color: #ffffff;\n      gap: 4px;\n      display: flex;\n      font-family: 'HelveticaNeue';\n      font-size: 20px;\n      font-weight: 400;\n\n      .langItem {\n        cursor: pointer;\n        transition: all .2s linear;\n      }\n    }\n\n    .yellow {\n      color: #EAD40C;\n    }\n  }\n}\n\n@media (max-width: 850px) {\n  .header {\n    .left {\n      \n      img {\n        width: 120px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `WelcomeHeader_header__Z+Vj7`,
	"left": `WelcomeHeader_left__U6rVL`,
	"right": `WelcomeHeader_right__L4Sro`,
	"lang": `WelcomeHeader_lang__wH28U`,
	"langItem": `WelcomeHeader_langItem__rLc5I`,
	"yellow": `WelcomeHeader_yellow__pKUFd`
};
export default ___CSS_LOADER_EXPORT___;
