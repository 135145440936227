import React from "react";
import styles from "../PromoGroup/PromoGroup.module.scss";
import box from "../../assets/images/box_1.png";
import box_mob from "../../assets/images/box_1_mob.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/logo_en_color.png'

const PromoGroup = () => {
  const { t } = useTranslation("promogroup");
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h1 className={styles.title}>
          МІНЯЙ ОЧКИ ДОСВІДУ НА ТОПОВІ ПРИЗИ
            {/* <span style={{ color: "#f8ff13" }}>{t("title_hg")}</span> */}
          </h1>
          <div className={styles.group}>
            <div className={styles.group_1}>
              <div className={styles.pic1}>
                <div className={styles.text}>
                  <p className={styles.name}>Скiни</p>
                  <div className={styles.desc}>{t("300 XP")}</div>
                </div>
              </div>
              <div className={styles.pic2}>
                <div className={styles.text}>
                  <p className={styles.name}>FreeSpins</p>
                  <div className={styles.desc}>{t("200 XP")}</div>
                </div>
              </div>
              <div className={styles.pic3}>
                <div className={styles.text}>
                  <p className={styles.name}>FreeBet</p>
                  <div className={styles.desc}>{t("2500 XP")}</div>
                </div>
              </div>
              <div className={styles.pic4}>
                <div className={styles.text}>
                  <p className={styles.name}>Гаджети</p>
                  <div className={styles.desc}>{t("2500 XP")}</div>
                </div>
              </div>
            </div>
            <div className={styles.group_2}>
              <img src={box} className={styles.group_2_img} />
              <img src={box_mob} className={styles.group_2_img_mob} />
              <div className={styles.desc_group}>
                <img src={logo}/>
                <h1 className={styles.desc_title}>
                БІЛЬШЕ ПРИЗІВ
                ВСЕРЕДИНІ
                </h1>
                <NavLink to="/shop">
                <button className={styles.btn}>{t("shop")}</button>
              </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoGroup;
