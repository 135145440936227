import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import logo from '../assets/images/logo.svg'

const PrivateRoute = ({ children }) => {

  const isLoading = useSelector(state => state.auth.isLoading)

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  if (isLoading) {
    return <div className='loader'>
      <svg width="180" height="180" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36.298 19.106 42 9h-2.844l-4.248 7.538H23.47L27.71 9h-2.846L19.19 19.118l1.402 2.53 1.426-2.53h11.433l-8.578 15.221h2.832l8.585-15.221h.007v-.012Z" fill="#AB886A"><animate attributeName="opacity" attributeType="XML" dur="2s" repeatCount="indefinite" values="1;0;1" /></path><path d="M22.007 9h-2.844l-2.865 5.01 1.43 2.577L22.008 9Z" fill="#AB886A"><animate attributeName="opacity" attributeType="XML" dur="2s" repeatCount="indefinite" values="1;0;1" /></path><path d="m2 9 14.254 25.34h2.919L4.869 9H2Z" fill="#AB886A"><animate attributeName="opacity" attributeType="XML" dur="2s" repeatCount="indefinite" values="1;0;1" /></path><path d="M36.294 9h-2.881l-2.816 5.068h2.846L36.293 9Z" fill="#AB886A"><animate attributeName="opacity" attributeType="XML" dur="2s" repeatCount="indefinite" values="1;0;1" /></path><path d="m26.311 21.649-4.28 7.592L10.572 9H7.73l14.286 25.314L29.2 21.649h-2.888Z" fill="#AB886A"><animate attributeName="opacity" attributeType="XML" dur="2s" repeatCount="indefinite" values="1;0;1" /></path></svg>
    </div>
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};
export default PrivateRoute